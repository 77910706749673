/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import "../../style/abstract/variables";
@import "../../style/abstract/mixins";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts";

.BlogPostView {
    .ContentWrapper {
        max-width: var(--content-wrapper-width);
    }

    &-Wrapper {
        margin-bottom: 35px;
        
        @include mobile {
            padding-top: 5px;
        }
    }

    &-ColumnWrapper {
        display: flex;
        column-gap: 20px;
    }

    &-ContentColumn {
        width: 100%;
    }

    &-CategoriesColumn {
        width: 100%;
        max-width: 440px;
    }

    &-Image {
        &,
        .Image-Image {
            position: static;
        }
    }

    &-Content {
        font-size: 18px;
        
        p,
        ul,
        li {
            font-weight: 300;
            font-size: 18px;
            line-height: 32px;
            color: var(--blog-post-card-content-color);
        }

        strong {
            font-weight: 500;
        }
    }

    &-Title {
        margin: 10px 0 5px;
        font-weight: 500;
        font-size: 45px;
        line-height: 55px;
    }

    &-PublishTime {
        margin: 6px 0;
        font-size: 16px;
    }
}